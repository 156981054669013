import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3518210"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "header-component" }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = { href: "/dashboard" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_app_bar, {
      flat: "",
      height: "125"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          justify: "center",
          align: "center"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("a", _hoisted_4, [
                  _createElementVNode("img", {
                    alt: "Rijksoverheid Logo",
                    src: require('@/assets/logo-ro.svg')
                  }, null, 8, _hoisted_5)
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_app_bar, {
      color: _ctx.colour,
      flat: "",
      height: "70"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_toolbar_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: { name: 'DashboardCharts' },
              style: {"color":"#ffffff"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Beheermodule Algemene Inzage ")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_app_bar_nav_icon, {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.drawer = !_ctx.drawer), ["stop"]))
        })
      ]),
      _: 1
    }, 8, ["color"]),
    (_ctx.getAuthentication())
      ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
          key: 0,
          modelValue: _ctx.drawer,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawer) = $event)),
          location: "right",
          width: "350"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuButtons, (item, i) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    is: item.enabled && !item.current ? 'router-link' : 'span',
                    key: i,
                    to: item.to
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, {
                        class: _normalizeClass(item.enabled ? (item.current ? 'current-page' : '') : 'disabled-item')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            class: "menu-item",
                            to: item.to
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.text), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ]),
                    _: 2
                  }, 1032, ["is", "to"]))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedTables, (groupData, group) => {
                  return (_openBlock(), _createElementBlock("div", { key: group }, [
                    (group === 'Overig')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                          _createVNode(_component_v_list_item, null, {
                            default: _withCtx(() => [
                              _createElementVNode("strong", null, _toDisplayString(group), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      : (_openBlock(), _createBlock(_component_router_link, {
                          key: 1,
                          class: "menu-item",
                          to: { name: 'table', params: { resource: groupData.resource } }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item, null, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  class: _normalizeClass({
                  'active-link':
                    _ctx.$route.name === 'table' && _ctx.$route.params.resource === groupData.resource
                })
                                }, [
                                  _createElementVNode("strong", null, _toDisplayString(group), 1)
                                ], 2)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["to"])),
                    _createVNode(_component_v_divider),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupData.tables.filter(
              (table) => table.resource !== groupData.resource && group !== groupData.label
            ), (t) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        key: t.label
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            class: "menu-item",
                            to: { name: 'table', params: { resource: t.resource } },
                            "active-class": "active-link"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(t.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}