import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_tooltip, {
      modelValue: _ctx.showToolTip,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showToolTip) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      "no-click-animation": "",
      scrollable: "",
      persistent: "",
      "max-width": _ctx.maxWidthDialog,
      "model-value": true,
      "onClick:outside": _cache[4] || (_cache[4] = ($event: any) => (_ctx.close()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            (_ctx.component.name !== 'OverviewEvtpTree')
              ? (_openBlock(), _createBlock(_component_v_card_title, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _mergeProps(_ctx.childProps, {
              onConfirm: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close())),
              onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close())),
              onRecordUpdated: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('recordUpdated')))
            }), null, 16))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["max-width"])
  ], 64))
}