import { createApp } from 'vue'
import { createVuetify } from 'vuetify'
import axios from 'axios'
import Keycloak from 'keycloak-js'
import App from './App.vue'
import store from './store'
import router from './router'
import 'vuetify/styles'

if (process.env.NODE_ENV === 'production') {
  store.commit('changeAPIurl', `${window.location.origin}/api`)
}

const vuetify = createVuetify({
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#01689B',
          secondary: '#94c7e0',
          tertiary: '#bedef0',
          quaternary: '#cae3f0',
          quinary: '#d2e5ee',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
          headerTextColour: '#000000',
          headerHoverColour: '#FCF29A'
        }
      }
    },
    cspNonce: 'eQw4j9WgXcB'
  }
})

export function getConfigs(): Promise<any> {
  return new Promise((resolve) => {
    axios
      .get(`${store.state.APIurl}/config/keycloak-env`, { timeout: 2000 })
      .then((response) => {
        console.log(response.data)
        resolve(response.data)
      })
      .catch(function (err) {
        console.log('failed to get config ' + err)
      })
  })
}

getConfigs().then((response: any) => {
  const initOptions: any = {
    url: `${response.keycloak_uri}`,
    realm: `${response.keycloak_realm}`,
    clientId: `${response.keycloak_client}`,
    onLoad: 'check-sso'
  }
  const keycloak = new Keycloak(initOptions)

  console.log('initializing keycloak')
  keycloak
    .init({
      onLoad: initOptions.onLoad,
      checkLoginIframe: true, // Explicitly enable login iframe check
      checkLoginIframeInterval: 30 // Check every 30 seconds
    })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('Not authenticated, attempting to login')
        // Attempt to login if not authenticated
        return keycloak.login({
          redirectUri: window.location.origin // Redirect back to current origin
        })
      }

      console.log('Authenticated successfully')

      // Setup axios interceptor
      axios.interceptors.request.use(
        (config) => {
          if (keycloak.token) {
            config.headers!.Authorization = `Bearer ${keycloak.token}`
          }
          return config
        },
        (error) => {
          return Promise.reject(error)
        }
      )

      // Commit keycloak to store
      store.commit('changeKeycloak', keycloak)

      // Create application
      createApp(App).use(router).use(store).use(vuetify).mount('#app')

      // Token Refresh with improved error handling
      setInterval(() => {
        keycloak
          .updateToken(70)
          .then((refreshed) => {
            if (refreshed) {
              console.log('Token refreshed', refreshed)
              // Update store or perform any necessary actions on token refresh
              store.commit('changeKeycloak', keycloak)
            } else {
              console.log(
                'Token not refreshed, still valid for ' +
                  Math.round(
                    keycloak.tokenParsed!.exp! + keycloak.timeSkew! - new Date().getTime() / 1000
                  ) +
                  ' seconds'
              )
            }
          })
          .catch((error) => {
            console.error('Failed to refresh token', error)

            // Attempt to re-authenticate if token refresh fails
            keycloak.login({
              redirectUri: window.location.origin
            })
          })
      }, 60000) // Reduced to every minute

      // Handle token expiration
      keycloak.onTokenExpired = () => {
        console.log('Token expired, attempting to refresh')
        keycloak.updateToken(70).catch(() => {
          keycloak.login({
            redirectUri: window.location.origin
          })
        })
      }
    })
    .catch((error) => {
      console.error('Error initializing Keycloak:', error)

      // More explicit error handling
      if (error === 'login_required') {
        keycloak.login({
          redirectUri: window.location.origin
        })
      }
    })
})
